<template>
  <div>
    <van-nav-bar
      title="添加培训记录"
      left-arrow
      @click-left="$router.back(-1)"
    />
    <van-form
      ref="vanForm"
      :show-error-message="false"
      label-width="22vw"
      @submit="onSubmit"
    >
      <van-field
        v-model="info.name"
        :rules="[{ required: true, message: '请填写培训名称' }]"
        label="培训名称"
        name="name"
        input-align="left"
        placeholder="请填写培训名称"
        maxlength="32"
        required
        @input="handleInput('name')"
      />
      <van-field
        :value="info.trainTypeName"
        clickable
        label="培训类别"
        name="trainType"
        placeholder="请选择"
        readonly
        @click="handlerTypesClick($event, 'trainType')"
      />
      <van-field
        key="完成日期"
        required
        :rules="[{ required: true, message: '请选择培训日期' }]"
        :value="info.trainingDate"
        label="培训日期"
        name="trainingDate"
        placeholder="请选择"
        readonly
        @click="onTimeClick"
      />
      <van-field
        :value="info.trainLevelName"
        clickable
        label="培训级别"
        name="trainLevel"
        placeholder="请选择"
        readonly
        @click="handlerTypesClick($event, 'trainLevel')"
      />
      <van-field
        :rules="[
          {
            required: true,
            message: '请选择培训结果',
            trigger: 'onChange'
          }
        ]"
        label="培训结果"
        name="radio"
        required
      >
        <template #input>
          <van-radio-group v-model="info.result" direction="horizontal">
            <van-radio
              v-for="item in resultData"
              :key="item.value"
              :name="item.value"
              >{{ item.label }}</van-radio
            >
            <!-- :disabled="+item.value === 1" -->
          </van-radio-group>
        </template>
      </van-field>
      <van-field class="file" name="fileList" label="附件" readonly>
        <template #input>
          <FileUploader
            v-model="info.attachments"
            :max-count="5"
            capture="camera"
          />
        </template>
      </van-field>
      <div class="details-page__footer-button-wrapper--fixed">
        <van-button
          class="btn-default"
          style="color: #000;margin-right:13px"
          round
          size="small"
          type="default"
          @click="onBack()"
          >取消
        </van-button>
        <van-button
          class="confirm-btn"
          round
          size="small"
          type="info"
          native-type="submit"
          >保 存
        </van-button>
      </div>
      <select-popup
        :choose-value="popupValue"
        :data="popupData"
        :title="popupTitle"
        :visible="popupVisible"
        :popup-style="{
          height: '70vh'
        }"
        @close="onPopupClose"
        @confirm="onPopupConfirm"
      />
      <van-popup v-model="showTimePicker" position="bottom">
        <van-datetime-picker
          v-model="initTime"
          type="date"
          @cancel="showTimePicker = false"
          @confirm="onDatetimeConfirm"
        />
      </van-popup>
    </van-form>
  </div>
</template>

<script>
import FileUploader from "@/components/fileuploader/FileUploader";
import { filterEmoji, operateMessage, parseTime } from "@/utils";
import { createPersonTraining } from "@/api/psm/contractor";
import getSelectMixin from "@/views/contractor/mixin/getSelectMixin";

import { mapState } from "vuex";
import SelectPopup from "@/components/SelectPopup";
import { FILE_ACCEPT_TYPES } from "@/components/fileuploader/util";

export default {
  components: {
    SelectPopup,
    FileUploader
  },
  mixins: [getSelectMixin],
  data() {
    return {
      FILE_ACCEPT_TYPES,
      showTimePicker: false,
      initTime: new Date(),
      popupVisible: false,
      nameVisible: false,
      popupTitle: "",
      popupData: [],
      resultData: [
        {
          label: "通过",
          value: 1
        },
        {
          label: "不通过",
          value: 0
        }
      ],
      info: {
        file: [],
        attachments: [],
        name: "",

        trainType: "",
        trainLevel: "",
        personId: "",
        result: 1,
        trainTypeName: "",
        trainLevelName: ""
      },
      popupType: "",
      popupTypeName: "",
      popupValue: "",
      popupTypesConf: {
        trainType: {
          title: "培训类别",
          keyName: "trainTypeName",
          typesKey: "classesTypes"
        },
        trainLevel: {
          title: "培训级别",
          keyName: "trainLevelName",
          typesKey: "levelTypes"
        }
      }
    };
  },
  created() {},
  computed: {
    ...mapState({})
  },
  methods: {
    onTimeClick() {
      this.info.trainingDate &&
        (this.initTime = new Date(this.info.trainingDate));

      this.showTimePicker = true;
    },
    onDatetimeConfirm(time) {
      this.info.trainingDate = parseTime(time, "{y}-{m}-{d}");

      this.showTimePicker = false;
    },
    onBack() {
      this.$router.back();
    },
    formatterGetInfo(result) {
      this.info.attachments =
        result.files &&
        result.files.map(file => {
          return {
            url: `${window.globalConfig.VUE_APP_FILE_URL}/file/download?downloadFlag=false&fileName=${file.id}`,
            name: file.name,
            fileInfo: file,
            isImage: true
          };
        });
      this.$refs["image-uploader"].setInnerValue(this.info.attachments);
    },
    handleInput(key) {
      this.info[key] = filterEmoji(this.info[key]);
    },
    handlerTypesClick(ev, key) {
      // 如果之前被选中了，那就直接传递过去
      this.popupValue = this.info[key];

      const popupTypesConf = this.popupTypesConf;
      this.popupVisible = true;
      this.popupTitle = popupTypesConf[key].title;

      this.popupData = [...this[popupTypesConf[key].typesKey]];
      this.popupType = key;
      this.popupTypeName = popupTypesConf[key].keyName;
    },
    onPopupConfirm(row) {
      this.info[this.popupType] = row.value;
      this.info[this.popupTypeName] = row.label;
    },
    onPopupClose() {
      this.popupVisible = false;
      this.popupData = [];
    },
    pageSave() {
      this.$refs.vanForm.submit();
    },

    async onSubmit() {
      try {
        const { id } = this.$route.params;
        this.info.personId = id;

        const r = await createPersonTraining(this.info);
        operateMessage(r, "保存");
        if (r) {
          this.$router.back(-1);
        }
      } catch (e) {
        console.log("onSave -> e", e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$--btn-bgc: #fd687d;
.van-form {
  height: 70vh;
  overflow: auto;
  .van-radio-group {
    display: flex;
  }
}
// .support-mobile {
//   margin: 3vh;
// }
.van-button {
  color: #fff;
  font-size: 16px;
}
.van-button--save-btn-bgc {
  background-color: $--button-color-feature;
  border-color: $--button-color-feature;
  box-shadow: $--button-color-feature-shadow;
  margin-right: 3vw;
}
.van-button--commit-btn-bgc {
  background-color: $--button-color-save;
  border-color: $--button-color-save;
  box-shadow: $--button-color-save-shadow;
}
</style>
