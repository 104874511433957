import { getSelectTrainType, getSelectTrainLevel } from "@/api/psm/contractor";

export default {
  data() {
    return {
      levelTypes: [],
      classesTypes: []
    };
  },
  created() {
    this.getTypes();
  },
  methods: {
    async getTypes() {
      try {
        const rets = await Promise.all([
          getSelectTrainType(),
          getSelectTrainLevel()
        ]);
        this.levelTypes = rets[1];
        this.classesTypes = rets[0];
      } catch (e) {
        console.log("getTypes -> e", e);
      }
    }
  }
};
