var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('van-nav-bar',{attrs:{"title":"添加培训记录","left-arrow":""},on:{"click-left":function($event){return _vm.$router.back(-1)}}}),_c('van-form',{ref:"vanForm",attrs:{"show-error-message":false,"label-width":"22vw"},on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"rules":[{ required: true, message: '请填写培训名称' }],"label":"培训名称","name":"name","input-align":"left","placeholder":"请填写培训名称","maxlength":"32","required":""},on:{"input":function($event){return _vm.handleInput('name')}},model:{value:(_vm.info.name),callback:function ($$v) {_vm.$set(_vm.info, "name", $$v)},expression:"info.name"}}),_c('van-field',{attrs:{"value":_vm.info.trainTypeName,"clickable":"","label":"培训类别","name":"trainType","placeholder":"请选择","readonly":""},on:{"click":function($event){return _vm.handlerTypesClick($event, 'trainType')}}}),_c('van-field',{key:"完成日期",attrs:{"required":"","rules":[{ required: true, message: '请选择培训日期' }],"value":_vm.info.trainingDate,"label":"培训日期","name":"trainingDate","placeholder":"请选择","readonly":""},on:{"click":_vm.onTimeClick}}),_c('van-field',{attrs:{"value":_vm.info.trainLevelName,"clickable":"","label":"培训级别","name":"trainLevel","placeholder":"请选择","readonly":""},on:{"click":function($event){return _vm.handlerTypesClick($event, 'trainLevel')}}}),_c('van-field',{attrs:{"rules":[
        {
          required: true,
          message: '请选择培训结果',
          trigger: 'onChange'
        }
      ],"label":"培训结果","name":"radio","required":""},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('van-radio-group',{attrs:{"direction":"horizontal"},model:{value:(_vm.info.result),callback:function ($$v) {_vm.$set(_vm.info, "result", $$v)},expression:"info.result"}},_vm._l((_vm.resultData),function(item){return _c('van-radio',{key:item.value,attrs:{"name":item.value}},[_vm._v(_vm._s(item.label))])}),1)]},proxy:true}])}),_c('van-field',{staticClass:"file",attrs:{"name":"fileList","label":"附件","readonly":""},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('FileUploader',{attrs:{"max-count":5,"capture":"camera"},model:{value:(_vm.info.attachments),callback:function ($$v) {_vm.$set(_vm.info, "attachments", $$v)},expression:"info.attachments"}})]},proxy:true}])}),_c('div',{staticClass:"details-page__footer-button-wrapper--fixed"},[_c('van-button',{staticClass:"btn-default",staticStyle:{"color":"#000","margin-right":"13px"},attrs:{"round":"","size":"small","type":"default"},on:{"click":function($event){return _vm.onBack()}}},[_vm._v("取消 ")]),_c('van-button',{staticClass:"confirm-btn",attrs:{"round":"","size":"small","type":"info","native-type":"submit"}},[_vm._v("保 存 ")])],1),_c('select-popup',{attrs:{"choose-value":_vm.popupValue,"data":_vm.popupData,"title":_vm.popupTitle,"visible":_vm.popupVisible,"popup-style":{
        height: '70vh'
      }},on:{"close":_vm.onPopupClose,"confirm":_vm.onPopupConfirm}}),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showTimePicker),callback:function ($$v) {_vm.showTimePicker=$$v},expression:"showTimePicker"}},[_c('van-datetime-picker',{attrs:{"type":"date"},on:{"cancel":function($event){_vm.showTimePicker = false},"confirm":_vm.onDatetimeConfirm},model:{value:(_vm.initTime),callback:function ($$v) {_vm.initTime=$$v},expression:"initTime"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }